.hero__highlights {
  position: relative;

  &:after {
    content: '';
    background: url(../img/home/highlights_bg.jpg) no-repeat;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -3;
    background-size: cover;
  }

  &__wrapper {
    width: 90%;
    max-width: 120rem;
    text-align: center;
    margin: 0 auto;
    padding: 10rem 0 10rem;

    @include respond(phone-large) {
      padding: 15rem 0 10rem;
    }

    @include respond(tab-land) {
      padding: 20rem 0 15rem;
    }

    @include respond(big-desktop) {
      padding: 35rem 0 25rem;
    }
  }

  &__group {
    @include respond(tab-land) {
      display: flex;
      column-gap: 2rem;
    }
  }
}

.webp .hero__highlights {
  &:after {
    background: url(../img/home/highlights_bg.webp) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}
