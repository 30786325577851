%boxContent {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  z-index: -1;
  clip-path: polygon(
    25px 0,
    100% 0,
    100% calc(100% - 25px),
    calc(100% - 25px) 100%,
    0 100%,
    0 25px
  );
}

.box {
  position: relative;
  text-align: center;
  width: 95%;
  max-width: 45rem;
  margin: 0 auto;

  &__shaped {
    display: inline-block;
    padding: 10px;

    &:before {
      @extend %boxContent;
      background: linear-gradient(
        to bottom right,
        rgba($color-box-dark, 0.7) 0%,
        rgba($color-box-light, 0.7) 40%,
        rgba($color-box-light, 0.7) 60%,
        rgba($color-box-dark, 0.7) 100%
      );
    }

    &__inner {
      display: inline-block;
      width: 100%;
      padding: 25px;

      &:before {
        @extend %boxContent;
        clip-path: polygon(
          8px 35px,
          35px 8px,
          calc(100% - 8px) 8px,
          calc(100% - 8px) calc(100% - 32px),
          calc(100% - 32px) calc(100% - 8px),
          8px calc(100% - 8px),
          8px 35px,
          11px 32px,
          11px calc(100% - 11px),
          calc(100% - 34px) calc(100% - 11px),
          calc(100% - 11px) calc(100% - 34px),
          calc(100% - 11px) 11px,
          37px 11px,
          11px 37px
        );
        background-color: rgba($color-box-border, 1);
      }
    }
  }
}

// 13px calc(3% + 3px),
// 13px calc(99% - 3px),
// calc(92% - 3px) calc(99% - 3px),
// calc(98% - 3px) calc(97% - 3px),
// calc(98% - 3px) 13px,
// calc(9% + 3px) 13px,
// 12px calc(3% + 4px)

// 10px 8%,
// 8% 10px,
// 98% 10px,
// 98% 92%,
// 92% 98%,
// 10px 98%,
// 10px 8%,
// 13px calc(8% + 3px),
// 13px calc(98% - 3px),
// calc(92% - 3px) calc(98% - 3px),
// calc(98% - 3px) calc(92% - 3px),
// calc(98% - 3px) 13px,
// calc(8% + 3px) 13px,
// 12px calc(8% + 4px)
