.hero__characters {
  position: relative;
  margin-top: -5rem;

  @include respond(tab-port) {
    margin-top: -8rem;
  }

  @include respond(tab-land) {
    margin-top: -12rem;
  }

  @include respond(big-desktop) {
    margin-top: -16rem;
  }

  &:after {
    content: '';
    background: url(../img/home/characters_bg.png) no-repeat;
    background-position: 50% 0%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-size: cover;

    @include respond(tab-port) {
      background-position: 50% 0%;
    }
  }

  &__wrapper {
    width: 90%;
    max-width: 100rem;
    text-align: center;
    margin: 0 auto;
    padding: 8rem 0;

    & img {
      display: block;
      width: 100%;
      max-width: 100rem;
      margin: 8rem auto 4rem;
    }

    h2 {
      margin-bottom: 3rem;
    }
  }
}

.webp .hero__characters {
  &:after {
    background: url(../img/home/characters_bg.webp) no-repeat;
    background-size: cover;
    background-position: 50% 0%;

    @include respond(tab-port) {
      background-position: 50% 0%;
    }
  }
}
