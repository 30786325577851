.paper__box {
  position: relative;
  text-align: center;
  width: 90%;
  max-width: 55rem;
  margin: 0 auto;

  @include respond(phone) {
    margin: 3rem auto;
  }

  &:after {
    content: '';
    background: url(../img/home/paper_bg.png) no-repeat;
    background-position: 97% 50%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
  }

  &__wrapper {
    padding: 4rem 2rem;
    width: 100%;

    @include respond(phone) {
      padding: 3.75rem 3rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 1rem auto;

    // @include respond(phone) {
    //   flex-direction: row;
    //   margin: 1.5rem auto 2rem;
    // }

    & img {
      width: 100%;
      display: float;

      // @include respond(phone) {
      //   flex-grow: 1;
      //   width: 40%;
      // }
      // @include respond(tab-port) {
      //   width: 45%;
      // }
      // @include respond(tab-land) {
      //   width: 42%;
      // }
      // @include respond(big-desktop) {
      //   width: 45%;
      // }
    }

    & p {
      @include respond(phone) {
        flex-grow: 2;
      }
    }
  }
}
