.navigation {
  background-color: $color-primary-dark;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;

  @include respond(tab-port) {
    display: initial;
  }

  &:after {
    content: '';
    background: url(../img/nav/nav_bg.png) bottom center no-repeat;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-size: cover;
    opacity: 0.5;
  }

  &__platformgroup {
    display: block;
    float: left;
    z-index: 6;
    position: relative;

    & img {
      width: 65px;
      height: auto;
      float: left;
      cursor: pointer;
    }
  }

  &__logo {
    position: absolute;
    right: 7rem;
    height: 6rem;
    top: 1rem;
    -webkit-filter: drop-shadow(0 0 5px $color-white);
    filter: drop-shadow(0 0 5px rgba($color-white, 0.75));
    display: none;

    @include respond(small) {
      display: block;
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  &__checkbox {
    display: none;
  }

  &__button {
    height: 4.75rem;
    width: 4.75rem;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 30;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__background {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    background-image: linear-gradient(
      rgba($color-primary-dark, 1) 0%,
      rgba($color-primary-dark, 0.95) 10%,
      rgba($color-primary-medium-dark, 0.85) 40%,
      rgba($color-primary-medium, 0.85) 75%,
      rgba($color-primary-light, 0.85) 100%
    );
    z-index: 10;
    transition: transform 0.5s;
    opacity: 1;
    box-shadow:
      inset 0 0 15px $color-primary-dark,
      inset 0 0 15px $color-primary-dark;
    border: 5px solid $color-gold-dark;

    @include respond(tab-port) {
      opacity: 0;
      display: none;
    }
  }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: -50%;
    z-index: 20;
    opacity: 0;
    width: 0;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    @include respond(tab-port) {
      position: relative;
      opacity: 1;
      width: 100%;
      height: 50px;
      z-index: 5;
      left: 0;
    }

    &--hide {
      display: none;
    }
  }

  &__list {
    @include absCenter;
    list-style: none;
    text-align: center;
    opacity: 0;
    transition: all 0.2s ease-out;
    width: 90%;
    max-width: 45rem;

    @include respond(tab-port) {
      float: right;
      display: flex;
      position: relative;
      top: initial;
      left: initial;
      transform: initial;
      align-items: center;
      height: 65px;
      opacity: 1;
      margin-right: 1rem;
      width: unset;
      max-width: unset;
    }
  }

  &__item {
    margin: 1rem 0.5rem;
    cursor: pointer;
    list-style: none;

    @include respond(tab-land) {
      margin: 1rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 3rem;
      color: $color-white;
      padding: 1rem 2rem; //updated this
      width: 100%; //updated this
      text-transform: uppercase;
      text-decoration: none;
      // background-image: linear-gradient(
      //   120deg,
      //   transparent 0%,
      //   transparent 50%,
      //   $color-primary-medium-dark 50%,
      //   $color-primary-medium 75%,
      //   $color-primary-medium-dark 100%
      // );
      // background-size: 240%;
      transition: all 0.4s;

      @include respond(tab-port) {
        padding: 2.4rem 1rem;
        color: $color-white;
        font-size: 16px;
        // background-image: linear-gradient(
        //   120deg,
        //   transparent 0%,
        //   transparent 50%,
        //   $color-primary-medium-dark 50%,
        //   $color-primary-medium 75%,
        //   $color-primary-medium-dark 100%
        // );
      }
    }
    &:hover,
    &:active {
      background-position: 100%;
      color: $color-primary-dark;
      transform: translateX(1rem);

      @include respond(tab-port) {
        color: $color-primary-dark;
      }
    }
  }

  &__active,
  &__hover {
    background: linear-gradient(
      to left,
      $color-gold-dark,
      $color-gold-light,
      $color-gold-dark
    );
    background-size: 100% 5px;
    background-position: bottom 0 left 0;
    background-repeat: no-repeat;

    @include respond(tab-port) {
      padding: 6.5px 0;
      background: linear-gradient(
        to left,
        $color-gold-dark,
        $color-gold-light,
        $color-gold-dark
      );
      background-size: 100% 5px;
      background-position: bottom 5px left 0;
      background-repeat: no-repeat;
    }
    @include respond(tab-land) {
      padding: 3.7px 0;
    }
  }

  //functionality
  &__checkbox:checked ~ &__background {
    transform: translateY(0);
  }
  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    left: 0;
  }
  &__checkbox:checked ~ &__nav &__list {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  //hamburger icons - little lines of the hamburger
  &__icon {
    position: relative;
    margin-top: 2.5rem;

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: $color-white;
      display: inline-block;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }
    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }

  //what makes hamburger work on hover and click.
  &__button:hover &__icon:before {
    top: -1rem;
  }
  &__button:hover &__icon:after {
    top: 1rem;
  }
  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }
  &__checkbox:checked + &__button &__icon:before {
    top: 0;
    transform: rotate(-135deg);
  }
  &__checkbox:checked + &__button &__icon:after {
    top: 0;
    transform: rotate(135deg);
  }
  &__checkbox:not(:checked) ~ &__small {
    opacity: 0;
  }
}
