.custom__select__wrapper {
  width: 90%;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
  flex: auto;
  position: relative;

  & aside {
    width: 100%;

    // @include respond(phone) {
    //   width: 65%;
    // }
  }
  @include respond(phone) {
    align-items: flex-start;
  }
}

.custom__select {
  position: relative;
  width: 100%;

  &__button {
    background-color: $color-black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    padding: 1rem;
    border: 2px solid $color-primary-border-main;
    box-shadow:
      0px 0px 5px $color-black,
      0px 0px 5px $color-white inset;

    position: relative;
    width: 100%;
    cursor: pointer;
  }

  &__value {
    text-align: left;
    color: $color-white;
    font-size: 1.6rem;
    font-weight: 700;

    @include respond(tab-port) {
      font-size: 1.8rem;
    }
  }

  &__arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid $color-white;
    transition: transform ease-in-out 0.3s;
  }

  &__dropdown {
    position: absolute;
    list-style: none;
    width: 100%;
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 6px 10px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
    background: $color-black;
    border: 1px solid $color-primary-border-main;
    margin-top: 0.3rem;
    transition: all 0.1s cubic-bezier(0.16, 1, 0.5, 1);
    transform: translateY(0.5rem);
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    font-weight: 700;

    &:focus-within {
      box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
    }

    & li {
      position: relative;
      cursor: pointer;
      display: flex;
      gap: 1rem;
      align-items: center;
      color: $color-white;
      border: 1px solid $color-primary-border-main;
      box-shadow: 0px 0px 5px $color-primary-border-main inset;
    }

    & li label {
      width: 100%;
      padding: 8px 10px;
      cursor: pointer;
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 25px;
    }

    & li:hover,
    & input:checked ~ label {
      background-color: $color-white;
      color: $color-primary-dark;
      box-shadow: 0px 0px 5px $color-primary-dark inset;
    }

    & input:focus ~ label {
      background-color: $color-white;
      color: $color-primary-dark;
      box-shadow: 0px 0px 5px $color-primary-dark inset;
    }

    & input[type='radio'] {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }

  &.active .custom__select__arrow {
    transform: rotate(180deg);
  }

  &.active .custom__select__dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0rem);
  }

  &__purchase__button {
    margin-top: 2rem;
  }

  //   @include respond(phone) {
  //     // position: absolute;
  //     // right: 0px;
  //     // align-self: unset;
  //   }

  //   & a {
  //     display: block;
  //     text-decoration: none;
  //     color: #fff;
  //     padding: 18px 20px;
  //   }

  //   &__hidden {
  //     display: none;
  //   }

  //   &--disabled {
  //     background-color: lightgray;
  //   }

  //   &__comingsoon {
  //     left: 50%;
  //     position: absolute;
  //     top: 120%;
  //     transform: translate(-50%);
  //     width: 100%;
  //   }
  //   &:hover {
  //     transform: scale(0.97);
  //     box-shadow:
  //       1px 1px 1px $color-black,
  //       0 0 5px $color-white inset;
  //   }
  // }
  & .hidden {
    display: none;
  }
}

aside.country,
aside.edition,
aside.platform,
aside.retailers {
  display: flex;
  align-items: flex-start;
  column-gap: 2rem;
  margin-top: 3rem;
  flex-direction: column;
  row-gap: 1rem;
  flex: auto;

  &__hidden {
    display: none;
  }

  @include respond(phone) {
    // flex-direction: row;
  }
  @include respond(tab-port) {
    // flex-direction: row;
    max-width: 70rem;
    margin: 3rem auto 0;
  }
}
