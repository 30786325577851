.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &__hide {
    opacity: 0;
  }

  &__fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
  }

  &__fadeInLeft {
    opacity: 0;
    animation-name: fadeInLeft;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 100px, 0);
  }

  20% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(100px, 0, 0);
  }

  10% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
