.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color-primary-dark, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__hide {
    display: none;
  }

  & a {
    text-decoration: underline;
  }
}
