.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: $color-primary-light;
  color: $color-white;
  border: 2px solid $color-white;
  padding: 12px 0;
  width: 30rem;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-shadow:
    0 0 15px rgba($color-black, 0.75),
    0 2px 4px rgba($color-black, 1);
  margin: 10px;
  box-shadow:
    inset 0 -5px 20px rgba($color-black, 0.4),
    inset 0 5px 20px rgba($color-white, 0.4),
    -8px 8px 5px rgba($color-black, 0.15),
    5px 10px 10px rgba($color-black, 0.2);
  transition:
    transform 0.15s,
    box-shadow 0.15s;

  @include respond(big-desktop) {
    width: 30rem;
  }

  &__subscribe {
    padding: 1.2rem;
    width: 80%;
    max-width: 64rem;
  }

  &__choice {
    width: 70%;
    max-width: 25rem;
    margin: 5px 10px 5px 0;
    padding: 8px 10px;
    font-size: $default-font-size;

    &--grey {
      background-color: rgba($color-grey-dark, 0.75);
    }

    &--black {
      background-color: $color-black;
    }

    &--disabled {
      background-color: rgba($color-grey-light-1, 1);
    }

    &--comingsoon {
      position: relative;
    }

    &--purchase {
      width: 90%;
      margin: 0 auto;
    }

    &__comingsoon__text {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
    }

    &--chart {
      width: 90%;
      margin: 0;
      padding: 3.5px;
      font-size: 1.2rem;
      max-width: 30rem;

      @include respond(phone) {
        font-size: 1rem;
      }
      @include respond(tab-port) {
        font-size: 1.5rem;
      }
    }
  }

  &:hover {
    transform: scale(0.95);
    box-shadow:
      inset 0 -5px 20px rgba($color-black, 0.4),
      inset 0 5px 20px rgba($color-white, 0.4),
      -10px 10px 5px rgba($color-black, 0.15),
      10px 12px 10px rgba($color-black, 0.2);
  }

  &--nav {
    @include respond(phone) {
      padding: 0.4rem 2.5rem;
      width: auto;
      min-width: 13rem;
      font-size: $default-font-size;

      // &:not(:first-child) {
      //   margin-right: 8rem;
      // }
    }
  }
  &--purchase {
    display: none;
    @include respond(phone) {
      display: inline-block;
    }
  }
  &--navLarge {
    padding: 0.4rem 1rem;
    width: auto;
    font-size: 1.18rem;
  }

  &--gold {
    background-color: $color-button-gold;
  }

  &--transparent {
    background-color: rgba($color-white, 0.4);
  }

  &--grey {
    background-color: $color-grey-light-1;
    transition: all 0.3s;

    &:hover {
      background-color: $color-button-red;
    }
  }

  &--opaque {
    opacity: 0;
  }
  &--hide {
    display: none;
  }

  &__comingsoon {
    color: $color-button-gold;
    @include absCenter;
    transform: translate(-50%, -50%) rotate(-3deg);
    font-size: 1.1rem;
    text-shadow:
      0 0 15px rgba($color-black, 0.75),
      0 2px 4px rgba($color-black, 1);
    width: 100%;

    @include respond(tab-port) {
      font-size: 1.3rem;
    }
  }
}

.btn__shaped {
  position: relative;
  filter: drop-shadow(0 0 6px rgba($color-secondary-shadow, 0.8));

  &:hover {
    filter: drop-shadow(0 0 3px rgba($color-secondary-shadow, 0.8));
  }

  & a {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-indent: -9999px;
    z-index: 1000;
  }

  &__BG {
    display: inline-block;
    padding: 10px 100px;
    width: auto;
    margin: 0 1rem;
    cursor: pointer;
    background-color: $color-white;
    // clip-path: polygon(5% 0, 100% 0, 100% 75%, 95% 100%, 0 100%, 0 25%);
    clip-path: polygon(
      0 15px,
      15px 0,
      100% 0,
      100% 15px,
      100% calc(100% - 15px),
      calc(100% - 15px) 100%,
      15px 100%,
      0 100%,
      0 15px
    );
    transform: skew(-10deg);
    transition: transform 0.15s;

    &--purchase {
      padding: 1.5rem 4rem;

      // @include respond(phone) {
      //   padding: 1rem 4rem;
      // }
    }

    &__text {
      transform: skew(10deg);
    }
    &:hover {
      transform: scale(0.95) skew(-10deg);
    }
    &:before {
      content: '';
      width: calc(98%);
      height: calc(88%);
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: linear-gradient(
        to right,
        $color-secondary-light,
        $color-secondary-dark
      );
      z-index: -2;
      // clip-path: polygon(5% 0, 100% 0, 100% 75%, 95% 100%, 0 100%, 0 25%);
      clip-path: polygon(
        0 15px,
        15px 0,
        100% 0,
        100% 15px,
        100% calc(100% - 15px),
        calc(100% - 15px) 100%,
        15px 100%,
        0 100%,
        0 15px
      );
    }
    &:after {
      content: '';
      background: url(../img/nav/nav_bg.png) center center no-repeat;
      height: 200%;
      width: 200%;
      display: block;
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -2;
      background-size: cover;
      opacity: 0.5;
    }
    &--large {
      padding: 1rem 3.3rem;

      @include respond(phone) {
        padding: 1rem 15rem;
      }
    }

    &--small {
      padding: 1rem 3.8rem;
    }

    &--disabled:before {
      background-color: lightgray;
      background: linear-gradient(
        to right,
        $color-grey-light-1,
        $color-grey-light-2
      );
    }
  }
  &__hidden {
    display: none;
  }
  &__comingsoon {
    left: 50%;
    position: absolute;
    top: 120%;
    transform: translate(-50%);
    width: 100%;
  }
}
button {
  border: none;
  background-color: transparent;
  margin: 0 auto;
}
