.gameplay {
  position: relative;

  &:after {
    content: '';
    background: url(../img/gameplay/gameplaybg_640.jpg) no-repeat;
    background-position: 0% 0%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 300px;
    left: 0;
    z-index: -3;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/gameplay/gameplaybg_900.jpg) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/gameplay/gameplaybg_1280.jpg) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
      top: 440px;
    }
    @include respond(big-desktop) {
      background: url(../img/gameplay/gameplaybg_1920.jpg) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
      top: 650px;
    }
  }

  &__top__bg {
    width: 100%;
    position: absolute;
    top: 65px;
    left: 0;
    z-index: -1;
  }

  &__wrapper {
    padding: 30rem 0 8rem;
    margin: 0 auto;
    width: 100%;
    max-width: 120rem;
    text-align: center;

    @include respond(tab-port) {
      padding: 25rem 0 8rem;
    }
    @include respond(tab-land) {
      padding: 15rem 0 8rem;
    }
  }

  &__selector {
    margin: 0 auto;
    width: 95%;
    max-width: 85rem;

    &__group {
      width: 100%;
      margin: 1rem auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & li {
        flex: 0 0 33%;
        list-style-type: none;
        position: relative;
        cursor: pointer;

        & img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }

    &--large {
      flex: 0 0 70% !important;
    }

    &__top {
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.farm:hover::before,
      &.gameplay__selector__active.farm::before {
        background: url('../img/gameplay/farm_on.png') center center / cover
          no-repeat;
      }
      &.ranch:hover::before,
      &.gameplay__selector__active.ranch::before {
        background: url('../img/gameplay/ranch_on.png') center center / cover
          no-repeat;
      }
      &.battle:hover::before,
      &.gameplay__selector__active.battle::before {
        background: url('../img/gameplay/battle_on.png') center center / cover
          no-repeat;
      }
      &.shrine:hover::before,
      &.gameplay__selector__active.shrine::before {
        background: url('../img/gameplay/shrine_on.png') center center / cover
          no-repeat;
      }
    }
  }

  & .box {
    margin: 2rem auto 0;
    position: relative;
    max-width: 50rem;

    & .box__shaped__inner {
      padding: 1.5rem;
    }

    & .box__shaped__text {
      line-height: unset;
    }
  }

  &__main__box {
    position: relative;
    border-radius: 15px;
    width: 100%;
    max-width: 85rem;
    margin: 3rem auto 0;
    overflow: hidden;
    border: 1.5px solid $color-white;

    &__header {
      width: 100%;
      position: relative;
      padding: 0.5rem 0 1.5rem;
      filter: drop-shadow(0 2px 3px $color-black)
        drop-shadow(0 2px 3px $color-black);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        z-index: -1;
        width: 100%;
        background: linear-gradient(
          to right,
          rgba($color-gradient-left, 1) 0%,
          rgba($color-gradient-right, 1) 100%
        );
        clip-path: polygon(
          0 0,
          100% 0,
          100% calc(100% - 15px),
          calc(50% - 15px) calc(100% - 15px),
          50% calc(calc(100% - 15px) + 15px),
          calc(50% + 15px) calc(100% - 15px),
          0 calc(100% - 15px),
          0 0
        );
      }
    }

    &__top {
      width: 100%;
      position: relative;

      & img {
        width: 100%;
        margin-top: -15px;
        position: relative;
        z-index: -1;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      top: -15px;
      margin-bottom: -15px;

      &:after {
        content: '';
        background: url(../img/gameplay/gearbg_640.png) no-repeat;
        background-position: 0% 0%;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        background-size: cover;

        @include respond(phone) {
          background: url(../img/gameplay/gearbg_800.png) no-repeat;
          background-position: 0% 0%;
          background-size: cover;
        }
      }
    }
  }

  &__sm__box {
    position: relative;
    width: 90%;
    margin: 3rem auto;
    padding: 2rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      z-index: -1;
      width: 100%;
      background: $color-box-light-2;
      clip-path: polygon(
        0 10px,
        10px 0,
        calc(100% - 10px) 0,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0 calc(100% - 10px),
        0 10px
      );
    }

    &__title {
      border-radius: 15px;
      background-color: $color-box-title;
      width: 90%;
      margin: 0 auto;
    }

    &__contents {
      margin: 2rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 2rem;
      column-gap: 2rem;
      width: 100%;

      @include respond(phone) {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      & img,
      & a {
        flex: 0 0 90%;
        width: 100%;
        cursor: pointer;

        @include respond(phone) {
          flex: 0 0 50%;
        }
      }

      &__text {
        padding: 0 1rem;
        width: 100%;

        @include respond(phone) {
          flex: 0 0 47%;
        }
      }
    }
  }

  &__shrine {
    width: 100%;
    margin-bottom: -15px;
  }

  &__hide {
    display: none;
  }

  // all the little images placed around the box
  &__extra {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
    }
    &--pengi__bombiliar {
      &:after {
        background: url(../img/gameplay/pengi_spitter.png) no-repeat;
        background-size: contain;
        width: 70px;
        height: 70px;
        left: -25px;
        bottom: -16px;

        @include respond(phone) {
          width: 90px;
          height: 90px;
          left: -20px;
          bottom: -30px;
        }
      }
      &:before {
        background: url(../img/gameplay/bombiliar_lamp.png) no-repeat;
        background-size: contain;
        width: 85px;
        height: 84px;
        right: -13px;
        bottom: -17px;

        @include respond(phone) {
          width: 80px;
          height: 85px;
          right: -18px;
          bottom: -27px;
        }
        @include respond(tab-port) {
          width: 95px;
          height: 95px;
          right: -18px;
          bottom: -24px;
        }
      }
    }
    &--book {
      &:after {
        background: url(../img/gameplay/ranch_book.png) no-repeat;
        background-size: contain;
        width: 100px;
        height: 70px;
        left: -30px;
        bottom: -20px;

        @include respond(phone) {
          width: 150px;
          height: 100px;
          left: -20px;
          bottom: -25px;
        }
      }
    }
    &--mood1 {
      &:after {
        background: url(../img/gameplay/monster_mood_status_1.png) no-repeat;
        background-size: contain;
        width: 40px;
        height: 70px;
        left: -10px;
        bottom: -40px;
        transform: rotate(-15deg);

        @include respond(phone) {
          width: 50px;
          height: 70px;
          left: -10px;
          bottom: -32px;
        }
      }
    }
    &--mood2 {
      &::before {
        background: url(../img/gameplay/monster_mood_status_2.png) no-repeat;
        background-size: contain;
        width: 40px;
        height: 70px;
        right: -10px;
        bottom: -40px;
        transform: rotate(15deg);

        @include respond(phone) {
          width: 50px;
          height: 70px;
          right: -10px;
          bottom: -32px;
        }
      }
    }

    &--enemydown {
      &:after {
        background: url(../img/gameplay/enemy_down.png) no-repeat;
        background-size: contain;
        width: 80px;
        height: 70px;
        right: 0;
        top: -40px;
        transform: rotate(25deg);

        @include respond(phone) {
          width: 120px;
          height: 50px;
          right: -20px;
          top: -52px;
        }
      }
    }
    &--unite {
      &:after {
        background: url(../img/gameplay/ui_unite_deco_lion.png) no-repeat;
        background-size: contain;
        width: 100px;
        height: 90px;
        right: -30px;
        top: -50px;

        @include respond(phone) {
          width: 120px;
          height: 100px;
          right: -30px;
          top: -67px;
        }
      }
    }
    &--buff {
      &:after {
        background: url(../img/gameplay/buff_group.png) no-repeat;
        background-size: contain;
        width: 100px;
        height: 90px;
        right: -27px;
        top: -80px;

        @include respond(small) {
          width: 130px;
          height: 100px;
          right: -37px;
          top: -80px;
        }

        @include respond(phone) {
          width: 120px;
          height: 80px;
          right: -30px;
          top: -77px;
        }
      }
    }
    &--farm {
      &:after {
        background: url(../img/gameplay/farm_icon.png) no-repeat;
        background-size: contain;
        width: 40px;
        height: 30px;
        left: -40px;
        top: -3px;
        filter: drop-shadow(2px 4px 6px $color-black);
      }
    }
    &--ranch {
      &:after {
        background: url(../img/gameplay/ranch_icon.png) no-repeat;
        background-size: contain;
        width: 40px;
        height: 30px;
        left: -40px;
        top: -3px;
        filter: drop-shadow(2px 4px 6px $color-black);
      }
    }
    &--battle {
      &:after {
        background: url(../img/gameplay/battle_icon.png) no-repeat;
        background-size: contain;
        width: 40px;
        height: 30px;
        left: -40px;
        top: -3px;
        filter: drop-shadow(2px 4px 6px $color-black);
      }
    }
  }
}

.webp .gameplay {
  &:after {
    background: url(../img/gameplay/gameplaybg_640.webp) no-repeat;
    background-position: 0% 0%;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/gameplay/gameplaybg_900.webp) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/gameplay/gameplaybg_1280.webp) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/gameplay/gameplaybg_1920.webp) no-repeat;
      background-position: 0% 0%;
      background-size: cover;
    }
  }
}
