.hero {
  position: relative;
  padding-top: 8rem;

  &__bg img {
    width: 100vw;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: 75% 0%;
  }

  &__diamond__free {
    position: absolute;
    width: 15rem;
    left: 5px;
    bottom: 30%;

    @include respond(phone) {
      width: 20rem;
      left: 10px;
    }
    @include respond(tab-port) {
      width: 18rem;
      // left: 2%;
      bottom: unset;
      top: 8rem;
    }
    @include respond(big-desktop) {
      top: 100px;
      // left: 5%;
      left: 15px;
    }
  }

  &__diamond {
    position: absolute;
    width: 15rem;
    right: 3px;
    top: 254px;

    @include respond(small) {
      right: 16px;
      top: 224px;
    }
    @include respond(phone) {
      width: 20rem;
      right: 3px;
      top: 242px;
    }
    @include respond(tab-port) {
      width: 18rem;
      left: 190px;
      bottom: unset;
      top: 8rem;
      right: unset;
    }
    @include respond(tab-land) {
      left: 215px;
    }
    @include respond(big-desktop) {
      top: 200px;
      left: 115px;
    }
  }

  &__container {
    padding: 2rem 0;
    width: 95%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__group {
    // height: 16rem;
    width: 100%;
    height: 80rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(phone) {
      height: 90rem;
    }

    @include respond(tab-port) {
      height: 73rem;
      justify-content: center;
      width: 48%;
      position: relative;
      transform: translate(-54%);
    }

    @include respond(tab-land) {
      width: 51%;
      height: 75rem;
      transform: translate(-48%);
    }

    @include respond(big-desktop) {
      height: 90rem;
      transform: translate(-45%);
    }
  }

  &__callout {
    margin-bottom: 0;
    @include respond(tab-port) {
      margin-bottom: 1.5rem;
    }
  }

  &__logo {
    width: 100%;
    max-width: 50rem;
    display: block;
    margin: 0 auto;
    -webkit-filter: drop-shadow(0 0 5px $color-white);
    filter: drop-shadow(0 0 5px rgba($color-white, 0.75));

    @include respond(tab-land) {
      max-width: 60rem;
    }
    @include respond(big-desktop) {
      max-width: 70rem;
    }
  }

  &__btn__group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 4rem auto 2rem;
    justify-content: center;
  }
}
