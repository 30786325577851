.videos {
  position: relative;

  &:after {
    content: '';
    background: url(../img/video/videobg_640.jpg) no-repeat;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/video/videobg_900.jpg) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/video/videobg_1280.jpg) bottom no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/video/videobg_1920.jpg) bottom no-repeat;
      background-size: cover;
    }
  }

  &__wrapper {
    padding: 14rem 0 8rem;
    margin: 0 auto;
    width: 95%;
    max-width: 120rem;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: flex-start;
  }
  &__item {
    cursor: pointer;
    position: relative;
    flex: 0 0 47%;

    @include respond(phone) {
      flex: 0 0 31.5%;
    }

    & img {
      width: 100%;
    }
  }
  &__playbutton {
    @include absCenter;
    opacity: 0.75;
    width: 3rem !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    z-index: 2;

    @include respond(phone) {
      width: 5rem !important;
    }
  }
}

.webp .videos {
  &:after {
    background: url(../img/video/videobg_640.webp) no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/video/videobg_900.webp) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/video/videobg_1280.webp) bottom no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/video/videobg_1920.webp) bottom no-repeat;
      background-size: cover;
    }
  }
}
