#purchase {
  position: relative;

  &:after {
    content: '';
    background: url(../img/purchase/purchasebg_640.jpg) bottom left no-repeat;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/purchase/purchasebg_900.jpg) bottom left no-repeat;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/purchase/purchasebg_1280.jpg) top center no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/purchase/purchasebg_1920.jpg) top center no-repeat;
      background-size: cover;
    }
  }
}
.purchase__wrapper {
  padding: 11rem 0 7rem;
  margin: 0 auto;
  text-align: center;
}

.purchase {
  // min-height: 100vh;
  // color: $color-black;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 120rem;
  row-gap: 3rem;
  column-gap: 3rem;
  padding: 1rem 1rem;

  @include respond(tab-port) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
  }
  @include respond(tab-land) {
    column-gap: 7rem;
  }

  &__left {
    @include respond(tab-port) {
      width: 100%;
      // max-width: 60rem;
      max-width: 100rem;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__right {
    @include respond(tab-port) {
      width: 100%;
      max-width: 78rem;
      flex: 0 1 100%;
      order: 2;
    }
  }

  &__desc__wrapper {
    margin-top: 5rem;
    text-align: left;

    & h1 {
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }

    & ul {
      margin-left: 0.75em;
      font-size: 1.6rem;
    }
  }

  &__glamshot {
    width: 100%;
    margin: 5rem auto 0;

    @include respond(tab-port) {
      margin: 1rem auto;
    }

    & img {
      width: 100%;
    }

    &--group {
      display: block;

      // @include respond(tab-port) {
      //   display: none;
      // }
    }
    &--outsidegroup {
      display: none;

      // @include respond(tab-port) {
      //   display: block;
      // }
    }
    &--hidden {
      display: none;
    }
  }
}

.webp #purchase {
  &:after {
    background: url(../img/purchase/purchasebg_640.webp) bottom left no-repeat;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/purchase/purchasebg_900.webp) bottom left no-repeat;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/purchase/purchasebg_1280.webp) top center no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/purchase/purchasebg_1920.webp) top center no-repeat;
      background-size: cover;
    }
  }
}
