body {
  font-family: montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.7;
  color: $color-primary-dark;
  z-index: 1;
  position: relative;

  @include respond(tab-port) {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

.make__white {
  color: $color-white;
  text-shadow:
    0 0 5px $color-primary-dark,
    0 0 5px $color-primary-dark;
}

.loader h1 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-style: normal;
  line-height: 1.7;
  color: $color-white;
  font-size: 3.75rem;
  text-transform: uppercase;
  font-weight: 800;

  @include respond(phone) {
    font-size: 6rem;
  }
}

.heading__primary {
  font-family: shippori-mincho, sans-serif;
  color: $color-white;
  display: inline-block;
  padding: 0 8rem;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba($color-primary-dark, 0.7) 20%,
    rgba($color-primary-dark, 0.7) 50%,
    rgba($color-primary-dark, 0.7) 80%,
    transparent 100%
  );
  border-right: none;
  border-width: 4px 0 4px 0;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      transparent 0%,
      rgba($color-primary-border-main, 0.7) 20%,
      rgba($color-primary-border-main, 0.7) 50%,
      rgba($color-primary-border-main, 0.7) 80%,
      transparent 100%
    )
    1;
  width: 100%;
  max-width: 70rem;

  @include respond(phone) {
    width: auto;
    padding: 0 15rem;
    font-size: 4rem;
  }

  &--sectionTitle {
    @include respond(phone) {
      font-size: 4.2rem;
    }
    @include respond(tab-port) {
      font-size: 5.2rem;
    }
  }

  &--sm {
    font-size: 2rem;
    border: none;
    border-image: none;
    padding: 1rem 4rem;

    @include respond(phone) {
      width: 100%;
    }
  }
  &--small {
    padding: 0rem 4rem;

    @include respond(phone) {
      padding: 0rem 15rem;
    }

    @include respond(tab-port) {
      padding: 0 20rem;
    }
  }

  &--gameplay {
    width: 100%;
    text-transform: capitalize;
    font-size: 2.5rem;
    background: $color-primary-dark;
    padding: 1rem;
    max-width: unset;

    @include respond(phone) {
      font-size: 3.5rem;
    }
  }
}
.heading__secondary {
  font-family: shippori-mincho, sans-serif;
  font-size: 4rem;
  font-weight: 900;
  color: $color-primary-dark;
  display: block;
  line-height: 1.3;
}

.heading__tertiary {
  color: $color-white;
  font-family: montserrat, sans-serif;
  text-shadow: 0 0 8px $color-black;
  font-weight: 800;
  font-size: 3rem;
  display: inline;
}

.paragraph {
  color: $color-white;
  font-size: 1.8rem;
  text-shadow: none;
  z-index: 1;
  position: relative;
  // text-shadow: 0 0 10px $color-black;

  &--dark {
    color: $color-black;
    font-style: italic;
  }
  &--black {
    color: $color-black;
  }
}

.policy {
  text-decoration: none;
  cursor: pointer;
  color: $color-white;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.3rem;
  &--blue {
    color: $color-primary-light;
  }
  &--white {
    color: $color-white;
  }
}

// %shared_text_info {
//   color: $color-white;
//   text-transform: uppercase;
// }

h1 {
  font-family: shippori-mincho, sans-serif;
  font-size: 3.75rem;
  text-transform: uppercase;
  font-weight: 800;

  @include respond(phone) {
    font-size: 6rem;
  }
}

h2 {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 800;
  font-style: italic;
  line-height: 2.5rem;

  @include respond(phone) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: $color-grey-dark-1;
}

h4 {
  font-size: 2rem;
  line-height: 1.25;
  z-index: 2;
  position: relative;
  display: block;
}

.upper {
  text-transform: uppercase;
}

// .outline {
//   color: $color-gold;
//   letter-spacing: 1.75px;

//   text-shadow:
//     1px 1px 0 $color-black,
//     -1px 1px 0 $color-black,
//     1px -1px 0 $color-black,
//     -1px -1px 0 $color-black;
//   position: relative;
//   z-index: 0;
//   @supports (-webkit-text-stroke: 2px $color-black) or
//     (text-stroke: 2px $color-black) {
//     text-shadow: none;
//     // -webkit-text-stroke: 2px $color-black;
//     // text-stroke: 2px $color-black;
//   }

//   &:before {
//     content: attr(title);
//     position: absolute;
//     left: 0;
//     z-index: -2;
//     @supports (-webkit-text-stroke: 2px $color-black) or
//       (text-stroke: 2px $color-black) {
//       -webkit-text-stroke: 0.11em $color-primary-neutral;
//       text-stroke: 0.11em $color-primary-neutral;
//     }
//   }
//   &:after {
//     content: attr(title);
//     position: absolute;
//     left: 0;
//     z-index: -3;
//     @supports (-webkit-text-stroke: 2px $color-black) or
//       (text-stroke: 2px $color-black) {
//       -webkit-text-stroke: 0.25em $color-white;
//       text-stroke: 0.25em $color-white;
//     }
//   }

//   &--large {
//     font-size: 1.75rem;
//   }
// }

.sup {
  font-size: 2.4rem;
}

.navigation__text {
  font-size: 3.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $color-gold-dark;
  font-weight: 900;
  line-height: 1.2;

  @include respond(phone) {
    font-size: 4rem;
  }

  @supports (-webkit-text-stroke: 2px $color-primary-dark) or
    (text-stroke: 1px $color-primary-dark) {
    -webkit-text-stroke: 1.5px $color-primary-dark;
    text-stroke: 1.5px $color-primary-dark;
    text-shadow: none;

    @include respond(tab-port) {
      -webkit-text-stroke: 0;
      text-stroke: 0;
    }
  }
  @supports (-webkit-text-fill-color: transparent) {
    background-image: linear-gradient(
      180deg,
      $color-gold-dark 0%,
      $color-gold-dark 30%,
      $color-gold-light 50%,
      $color-gold-dark 80%,
      $color-gold-dark 100%
    );
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include respond(tab-port) {
    font-size: 16px;
  }
  @include respond(tab-land) {
    letter-spacing: 1.3px;
  }
}

.btn__shaped__BG__text {
  color: $color-primary-dark;
  font-size: 2rem;
  white-space: nowrap;
  font-weight: bold;
  text-shadow:
    1px 1px 0 $color-white,
    -1px 1px 0 $color-white,
    1px -1px 0 $color-white,
    -1px -1px 0 $color-white;
  // @supports (-webkit-text-stroke: 2px $color-white) or
  //   (text-stroke: 2px $color-white) {
  //   text-shadow: none;
  //   -webkit-text-stroke: 1px $color-white;
  //   text-stroke: 1px $color-white;
  // }

  @include respond(phone) {
    font-size: 1.99rem;
  }

  &--upper {
    text-transform: uppercase;
    font-size: 2.25rem;

    @include respond(small) {
      font-size: 2.75rem;
    }
  }

  &--small {
    font-size: 2rem;
  }
}

.callout__text {
  font-family: shippori-mincho, sans-serif;
  text-shadow:
    0 0 6px $color-white,
    0 0 8px $color-white,
    0 0 8px $color-white;
  font-size: 2.3rem;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 50rem;

  @include respond(phone) {
    font-size: 2.5rem;
    line-height: 1.5;
  }

  @include respond(tab-port) {
    font-size: 3rem;
  }
}

.paper__box__text {
  line-height: 1.4;

  @include respond(tab-port) {
    font-size: 1.6rem;
  }
  & span {
    font-style: italic;
  }
}

.paper__box__title,
.gameplay__sm__box__title {
  font-size: 2rem;
  font-weight: 700;
}

.box__shaped__text {
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-shadow:
    0 0 5px $color-white,
    0 0 5px $color-white;

  @include respond(small) {
    font-size: 1.6rem;
    line-height: inherit;
  }
  @include respond(phone-large) {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
  @include respond(tab-port) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  @include respond(tab-land) {
    line-height: inherit;
  }
}
.modal .box__shaped__text {
  line-height: inherit;
}
.characters__highlight {
  text-transform: unset;
  font-weight: 400;
}

.characters__desc,
.gameplay__text {
  font-size: 1.6rem;

  @include respond(phone) {
    font-size: 1.8rem;
  }

  @include respond(tab-port) {
    font-size: 2.2rem;
  }
}
.gameplay__text {
  color: $color-white;
  padding: 0 1rem;

  @include respond(phone) {
    padding: 0 10rem;

    &--shrine {
      padding: 0 1rem;
    }
  }

  &--bold {
    font-weight: 800;
  }
}
.gameplay__sm__box__contents__text {
  line-height: 1.25;
  text-align: center;
  position: relative;
  @include respond(phone) {
    text-align: left;
  }
  & div {
    z-index: 2;
    position: relative;
  }
}

.footer__license__text {
  color: $color-white;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 2rem;
  display: inline-block;
  font-weight: 600;
}

.custom__select__comingsoon__text {
  font-size: 2rem;
  margin-top: 1rem;
}
