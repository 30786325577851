.story {
  position: relative;

  &:after {
    content: '';
    background: url(../img/story/storybg_640.jpg) bottom no-repeat;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/story/storybg_900.jpg) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/story/storybg_1280.jpg) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/story/storybg_1920.jpg) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  &__wrapper {
    padding: 14rem 0 8rem;
    margin: 0 auto;
    width: 95%;
    max-width: 120rem;
    text-align: center;
  }

  &__img {
    width: 100%;
    max-width: 40rem;
    position: relative;

    @include respond(tab-port) {
      position: absolute;
      width: 44%;
    }

    @include respond(tab-land) {
      width: 50%;
    }

    &--1 {
      left: 0;
      top: 14.5%;
      transform: translate(0);

      @include respond(tab-port) {
        transform: translate(-50%);
      }

      @include respond(tab-land) {
        transform: translate(-72%);
        top: 13.5%;
      }
    }

    &--2 {
      right: 0;
      top: 38.5%;
      transform: translate(0);

      @include respond(tab-port) {
        transform: translate(50%);
      }

      @include respond(tab-land) {
        transform: translate(72%);
        top: 37.5%;
      }
    }

    &--3 {
      left: 0;
      top: 59%;
      transform: translate(0);

      @include respond(tab-port) {
        transform: translate(-50%);
      }

      @include respond(tab-land) {
        transform: translate(-72%);
        top: 58.5%;
      }
    }
  }

  & .box {
    margin: 4rem auto;
    position: relative;
    max-width: 70rem;

    @include respond(phone) {
      margin: 8rem auto 0;
    }

    & .box__shaped__inner {
      padding: 2rem;

      @include respond(small) {
        padding: 2.5rem;
      }

      @include respond(tab-port) {
        padding: 3rem 15rem;
      }
    }

    & .box__shaped__text {
      line-height: unset;
    }
  }
}

.webp .story {
  &:after {
    background: url(../img/story/storybg_640.webp) no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/story/storybg_900.webp) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/story/storybg_1280.webp) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/story/storybg_1920.webp) no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }
}
