.imagegroup {
  display: flex;
  flex-wrap: wrap;
  max-width: 178rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  justify-content: center;

  &__item {
    flex: 0 1 95%;
    position: relative;
    margin: 1rem;
    cursor: pointer;
    max-width: 75rem;
    margin: 4rem auto;
  }
  &__playbutton {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    opacity: 0.75;
    width: 8rem;
    z-index: 2;
    filter: drop-shadow(0 0 4px $color-white);
  }

  &__video {
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  &__itemDesign {
    max-width: 60rem;
    width: 100%;
    position: relative;
    z-index: 1;
    border: 3px solid $color-primary-border-main;
    box-shadow:
      0px 0px 5px $color-black,
      0px 0px 10px $color-black,
      0px 0px 10px $color-white inset;

    &--large {
      max-width: 85rem;
    }
  }
}
