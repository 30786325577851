.characters {
  position: relative;

  &:after {
    content: '';
    background: url(../img/character/characterbg_640.jpg) top center no-repeat;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/character/characterbg_900.jpg) top center no-repeat;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/character/characterbg_1280.jpg) top center
        no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/character/characterbg_1920.jpg) top center
        no-repeat;
      background-size: cover;
    }
  }

  &__wrapper {
    padding: 14rem 0 8rem;
    margin: 0 auto;
    width: 95%;
    max-width: 120rem;
    text-align: center;
  }

  &__selector {
    width: 100%;
    max-width: 120rem;
    margin: 3rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      flex: 0 0 30%;
      list-style-type: none;
      position: relative;
      cursor: pointer;

      @include respond(small) {
        flex: 0 0 25%;
      }
      @include respond(phone) {
        flex: 0 0 18%;
      }
      @include respond(phone-large) {
        flex: 0 0 12%;
      }
      @include respond(tab-port) {
        flex: 0 0 10%;
      }

      & img {
        width: 100%;
      }
    }
  }

  &__thumb {
    filter: drop-shadow(0px 0px 10px $color-primary-border-main);
    opacity: 0.7;
    &:hover {
      filter: drop-shadow(0 0 10px $color-primary-border-main)
        drop-shadow(0 0 10px $color-white);
      opacity: 1;
    }
  }
  &__active {
    filter: drop-shadow(0 0 10px $color-primary-border-main)
      drop-shadow(0 0 10px $color-white);
    opacity: 1;
  }

  &__highlight {
    margin: 1rem auto 2rem;
  }

  &__image {
    width: 60%;
    max-width: 30rem;
    @include respond(tab-port) {
      flex: 0 0 20%;
      width: 100%;
    }
  }

  &__content {
    flex: 0 0 65%;
  }

  & .box {
    margin: 4rem auto;
    position: relative;
    max-width: 120rem;

    @include respond(phone) {
      margin: 5rem auto 0;
    }

    & .box__shaped__inner {
      padding: 2rem;
      display: flex;
      flex-direction: column;

      @include respond(small) {
        padding: 2.5rem;
      }

      @include respond(phone) {
        padding: 4.5rem;
      }

      @include respond(tab-port) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        column-gap: 2rem;
        padding: 5rem 3rem;
      }
    }

    & .box__shaped__text {
      line-height: unset;
    }
  }
}

.webp .characters {
  &:after {
    background: url(../img/character/characterbg_640.webp) top center no-repeat;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/character/characterbg_900.webp) top center
        no-repeat;
      background-size: cover;
    }
    @include respond(tab-land) {
      background: url(../img/character/characterbg_1280.webp) top center
        no-repeat;
      background-size: cover;
    }
    @include respond(big-desktop) {
      background: url(../img/character/characterbg_1920.webp) top center
        no-repeat;
      background-size: cover;
    }
  }
}
