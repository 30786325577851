.carousel {
  margin: 0 auto;
  width: 100%;
  max-width: 55rem;
  position: relative;

  @include respond(phone) {
    width: 31.5rem;
  }
  @include respond(phone-large) {
    width: 33rem;
  }
  @include respond(tab-port) {
    width: 36rem;
  }

  &__prev,
  &__next {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 3rem !important;
  }
  &__prev {
    left: 3%;
    z-index: 1;

    @include respond(tab-port) {
      left: 2%;
    }
  }
  &__next {
    right: 3%;

    @include respond(tab-port) {
      right: 2%;
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    // padding: 2rem 0;
  }

  &__item {
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    position: relative;

    & img {
      width: 100%;
    }
  }
  &__playbutton {
    @include absCenter;
    opacity: 0.75;
    width: 5rem !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    z-index: 2;
  }
}
