.loader {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: $color-primary-dark;
  align-items: center;
  justify-content: center;
  z-index: 10020;
  opacity: 1;
  transition: opacity 1500ms;
  top: 0;
  left: 0;
  text-align: center;

  &__fade {
    opacity: 0;
  }
  &__hide {
    display: none;
  }

  &__img {
    width: 80%;
    max-width: 60rem;
    animation: spin 8s linear infinite;
  }

  &__text__group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  &__dots {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-white;
    color: $color-white;
    margin: 3px;
  }
  &__dots--1 {
    animation: dots1 1.5s infinite linear;
  }
  &__dots--2 {
    animation: dots2 1.5s infinite linear;
  }
  &__dots--3 {
    animation: dots3 1.5s infinite linear;
  }

  @keyframes dots1 {
    0% {
      top: 0;
    }
    16.667% {
      top: -10px;
    }
    100% {
      top: 0;
    }
  }

  @keyframes dots2 {
    0% {
      top: 0;
    }
    50% {
      top: -10px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes dots3 {
    0% {
      top: 0;
    }
    83.333% {
      top: -10px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
