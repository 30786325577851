.hero__story {
  position: relative;
  margin: -26rem auto -5rem;

  @include respond(phone-large) {
    margin: -20rem auto -11rem;
  }

  @include respond(tab-port) {
    margin: -22rem auto -13rem;
  }

  @include respond(tab-land) {
    margin: -30rem auto -18rem;
  }

  @include respond(big-desktop) {
    margin: -42rem auto -25rem;
  }

  &__bg {
    img {
      width: 100%;
      position: relative;
      z-index: -1;
    }
  }

  &__wrapper {
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
    max-width: 120rem;
    text-align: center;
    margin: 0 auto;
    padding: 8rem 0 25rem;

    @include respond(tab-port) {
      top: 12%;
    }
    @include respond(big-desktop) {
      top: 15%;
    }

    & img {
      display: block;
      width: 100%;
      max-width: 70rem;
      margin: 4rem auto;
    }

    h2 {
      margin-bottom: 3rem;
    }

    & .box {
      margin: 2rem auto;

      @include respond(phone) {
        margin: 8rem auto;
      }

      @include respond(phone-large) {
        margin: 2rem auto;
        transform: translate(-30%);
      }

      @include respond(tab-port) {
        margin: 6rem auto;
        transform: translate(-50%);
      }

      @include respond(tab-land) {
        margin: 8rem auto;
      }

      @include respond(big-desktop) {
        margin: 15rem auto;
        transform: translate(-70%);
      }

      & .box__shaped__inner {
        padding: 2rem;

        @include respond(small) {
          padding: 25px;
        }
        @include respond(phone-large) {
          padding: 2rem;
        }
        @include respond(tab-port) {
          padding: 2rem;
        }
      }

      & .btn__shaped {
        margin: 2rem auto 0;
      }
    }
  }
}
