.hero__videos {
  position: relative;

  &:after {
    content: '';
    background: url(../img/home/video_bg.jpg) no-repeat;
    background-position: 80% 0%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/home/video_bg.jpg) no-repeat;
      background-size: cover;
      background-position: 80% 0%;
    }

    @include respond(big-desktop) {
      background-position: 80% 35%;
    }
  }

  &__wrapper {
    width: 90%;
    max-width: 120rem;
    text-align: center;
    margin: 0 auto;
    padding: 8rem 0 30rem;
    position: relative;
    z-index: 1;

    @include respond(phone-large) {
      padding: 8rem 0 25rem;
    }

    @include respond(tab-land) {
      padding: 8rem 0 30rem;
    }
    @include respond(big-desktop) {
      padding: 8rem 0 35rem;
    }
  }
}

.webp .hero__videos {
  &:after {
    background: url(../img/home/video_bg.webp) no-repeat;
    background-size: cover;
    background-position: 80% 0%;

    @include respond(tab-port) {
      background: url(../img/home/video_bg.webp) no-repeat;
      background-size: cover;
      background-position: 80% 0%;
    }
    @include respond(big-desktop) {
      background-position: 80% 50%;
    }
  }
}
