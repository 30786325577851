.hero__gameplay {
  position: relative;

  &:after {
    content: '';
    background: url(../img/home/gameplay_bg_sm.jpg) no-repeat;
    background-position: 50% 0%;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    background-size: cover;

    @include respond(tab-port) {
      background: url(../img/home/gameplay_bg.jpg) no-repeat;
      background-size: cover;
      background-position: 50% 60%;
    }

    @include respond(big-desktop) {
      background-position: 50% 35%;
    }
  }

  &__wrapper {
    width: 90%;
    max-width: 120rem;
    text-align: center;
    margin: 0 auto;
    padding: 8rem 0;

    & h2 {
      max-width: 90rem;
      margin: 0 auto;
    }

    & img {
      cursor: pointer;
      display: block;
      width: 100%;
      max-width: 70rem;
      margin: 4rem auto;
      border: 3px solid $color-primary-border-main;
      box-shadow:
        0px 0px 5px $color-black,
        0px 0px 10px $color-black,
        0px 0px 10px $color-white inset;
    }
  }
}

.webp .hero__gameplay {
  &:after {
    background: url(../img/home/gameplay_bg_sm.webp) no-repeat;
    background-size: cover;
    background-position: 50% 0%;

    @include respond(tab-port) {
      background: url(../img/home/gameplay_bg.webp) no-repeat;
      background-size: cover;
      background-position: 50% 60%;
    }
    @include respond(big-desktop) {
      background-position: 50% 35%;
    }
  }
}
